import { getParams, removeUrlParam, isInWeiXin } from '../utils/index'
import { loginApi } from '../api/index'
const wxauth = {
  data () {
    return {
      appid: 'wxc6148c94b48d508a',
      inviterCode: '', // 邀请者信息
      wx_code: '',
      token: '',
    }
  },
  created () {
    const token = localStorage.getItem('SURVEY_token')
    if (getParams('recordIdentifier')) {
      this.inviterCode = getParams('recordIdentifier')
    }
    if (getParams('inviterCode')) {
      this.inviterCode = getParams('inviterCode')
    }
    if (token) { // 已经登录
      this.token = token
      if (this.doWxAuthCb) {
        this.doWxAuthCb(true)
      }
    } else if (getParams('code')) { // 已经授权
      this.wx_code = getParams('code')
      this.dologinApi()
    } else { // 去微信授权
      this.goWxAuth()
    }

  },
  mounted () {
  },
  methods: {
    wxAuthInit (type) {
      if (type === 'force') {
        const url = removeUrlParam('code')
        this.goWxAuth(url)
      } else {
        if (getParams('code')) {
          console.log()
        } else {
          const token = localStorage.getItem('SURVEY_token')
          if (token) { // 已经登录
            this.token = token
            if (this.doWxAuthCb) {
              this.doWxAuthCb(true)
            }
          } else {
            this.goWxAuth()
          }
        }
      }
    },
    goWxAuth (url = location.href) {
      const appid = this.appid
      const _this = this
      url = encodeURIComponent(url)
      if (isInWeiXin()) {
        url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
        location.replace(url)
        // console.log('url:', url)
      } else {
        this.$dialog.show({
          type: 'ios',
          title: null,
          content: '您暂未登录，请登录后再进行相关操作。',
          showCancel: false,
          okText: '去登录',
          onOk: function () {
            let query = {
              returnUrl: location.href
            }
            const inviterCode = _this.inviterCode
            if (inviterCode) {
              query = Object.assign(query, {inviterCode})
            }
            console.log('query:', query)
            _this.$router.push({name: 'login', query})
          }
        })
      }
      console.log('url:', url)
    },
    async dologinApi () {
      let params = {
        param: {
          wechatCode: this.wx_code, // 001CH6100DqbLN18AS0001a6WE0CH61L
          inviterCode: this.inviterCode
        }
      }
      let res = {}
      try {
        res = await loginApi(params)
        if (res.code === '0000') {
          const token = res.result.token
          this.token = token
          localStorage.setItem('SURVEY_token', token)
          if (res.result.registerCompleted === 'N') { // 没有手机号码 则跳转到手机号码编辑页面
            const _this = this
            this.$dialog.show({
              type: 'ios',
              title: null,
              content: '您暂未绑定手机号，请绑定后再进行相关操作。',
              showCancel: false,
              okText: '去绑定',
              onOk: function () {
                _this.$router.push({name: 'editMobile'})
              }
            })
          }
          if (this.doWxAuthCb) {
            this.doWxAuthCb(true)
          }
        } else if (res.code === 'BIZ_1000') {
          this.wxAuthInit('force')
        } else {
          if (this.doWxAuthCb) {
            this.doWxAuthCb(false)
          }
        }
      } catch (err) {
        if (this.doWxAuthCb) {
          this.doWxAuthCb(false)
        }
      }
    },
  }
}
export default wxauth