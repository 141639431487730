<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <div class="verify-old">
        <div
          class="
            public-areas public-area-user-list
            margin-bottom-10 margin-top-10
          "
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__hd">
                <label class="weui-label">验证码</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    id="vcode"
                    type="text"
                    maxlength="6"
                    placeholder="请输入验证码"
                    v-model="captcha"
                  />
                </p>
              </div>
              <div class="weui-cell__ft" @click="sendSms" v-if="!countDownStatus">
                <button class="weui-vcode-btn btn-verify public-btn-vcode">
                  获取验证码
                </button>
              </div>
              <div class="weui-cell__ft" v-else>
                <span class="weui-vcode-btn btn-verify-new public-btn-vcode disabled">{{seconds}}s 后发送</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            public-areas public-area-user-list
            margin-bottom-10 margin-top-10
          "
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__hd">
                <label class="weui-label">新密码</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    id="password"
                    type="password"
                    maxlength="20"
                    placeholder="请输入6~20位密码"
                    v-model="password"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="public-area-submit-box">
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__bd">
                <a
                  href="javascript:;"
                  class="weui-btn weui-btn_primary public-btn-user-edit"
                  @click="willSet"
                  >保 存</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar :selectedVal="'users'"></tabbar>
  </div>
</template>

<script>
import sha256 from 'sha256'
import { isInWeiXin } from '../utils/index'
import { getBindSmsCaptcha, setPassword } from '../api/index'
import tabbar from '../components/tabbar/index.vue'
import wxauth from '../mixin/wxauth'
export default {
  mixins: [wxauth],
  name: "setPassWord",
  components: {
    tabbar
  },
  data () {
    return {
      countDownStatus: false, // 发送验证码的60秒倒计时内
      timer: null,
      seconds: 60,
      password: '',
      captcha: '',
      uniqueId: '',
    }
  },
  created () {

  },
  methods: {
    // 发送绑定的手机号的验证码
    async doGetBindSmsCaptcha () {
      let params = {
        param: {
          bizCode: 'ChangePassword'
        }
      }
      let res = {}
      try {
        res = await getBindSmsCaptcha(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          this.uniqueId = result.uniqueId
          this.$toast.info('验证码已发送，请查收')
          this.countDown()
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    async doSetPassword () {
      const pwd = sha256(this.password)
      let params = {
        param: {
          password: pwd,
          captcha: this.captcha,
          captchaUniqueId: this.uniqueId
        }
      }
      let res = {}
      try {
        res = await setPassword(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          this.$toast.info('密码设置成功')
          this.token = ''
          localStorage.removeItem('SURVEY_token')
          if (isInWeiXin()) {
            setTimeout(() => {
              this.$router.push({name: 'users'})
            }, 1000)
          } else {
            setTimeout(() => {
              this.$router.push({name: 'login'})
            }, 1000)
          }
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
          this.$toast.info(res.message)
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    sendSms () {
      this.doGetBindSmsCaptcha()
    },
    willSet () {
      this.doSetPassword()
    },
    countDown () {
      this.countDownStatus = true
      this.timer = setInterval(() => {
        this.seconds--
        if (this.seconds === 0) {
          this.countDownStatus = false
          this.seconds = 60
          clearInterval(this.timer)
        }
      }, 1000)
    }
  }
};
</script>

<style lang="css" scoped>
.public-container{
  margin: unset;
}
.weui-btn.public-btn-user-edit{
  padding: unset;
}
</style>